import { Button, Icon } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { Asset, AssetCategories } from '../../data-types';
import { UploadButton } from './UploadButton';
import UnsupportedFileModal from './UnsupportedFileModal';

export interface Props {
    asset: Asset;
    canChangeFormat: boolean;
    formatting: boolean;
    loadingFile: boolean;
    loadingArtist: boolean;
    onChangeFormat: () => void;
    onFileChange: (files: File[]) => void;
    onLoadArtistImage: () => void;
}

const validFileTypes = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png'];

const ImageMediaFooter = (props: Props): JSX.Element => {
    const [modalVisible, setModalVisible] = useState(false);
    const [unsupportedFileNames, setUnsupportedFileNames] = useState(['']);
    const acceptType = validFileTypes.join(',');
    const loadingMedia = props.loadingFile || props.loadingArtist;
    const isDefaultArtist = props.asset.category == AssetCategories.DEFAULT_ARTIST_IMAGE;
    const missingArtistData = !(props.asset.asin?.length && props.asset.mtr?.length);

    const onFileChange = (files: File[]) => {
        if (files.length < 1) {
            return;
        }
        const file = files[0];
        if (!validFileTypes.includes(file.type)) {
            setUnsupportedFileNames([file.name]);
            setModalVisible(true);
            return;
        }
        props.onFileChange([file]);
    };

    return (
        <>
            <UnsupportedFileModal
                visible={modalVisible}
                fileNames={unsupportedFileNames}
                validFormats={validFileTypes}
                onExit={() => setModalVisible(false)}
            />
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-12">
                        <div className="awsui-util-f-l">
                            {!isDefaultArtist && (
                                <UploadButton
                                    variant="link"
                                    icon="file-open"
                                    accept={acceptType}
                                    multiple={false}
                                    onFileChange={onFileChange}
                                    loading={props.loadingFile}
                                    disabled={loadingMedia}
                                >
                                    Choose file
                                </UploadButton>
                            )}
                            {(!isDefaultArtist || !missingArtistData) && (
                                <Button
                                    variant="link"
                                    icon="download"
                                    onClick={() => props.onLoadArtistImage()}
                                    loading={props.loadingArtist}
                                    disabled={loadingMedia || missingArtistData}
                                >
                                    Load artist
                                </Button>
                            )}
                            {isDefaultArtist && missingArtistData && (
                                <span className="awsui-util-status-negative">
                                    <Icon name="status-warning" /> Provide an ASIN and marketplace
                                </span>
                            )}
                            {props.canChangeFormat && (
                                <Button
                                    variant="link"
                                    icon="settings"
                                    disabled={loadingMedia}
                                    loading={props.formatting}
                                    onClick={() => props.onChangeFormat()}
                                >
                                    Change format
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageMediaFooter;
