import { InterludeOutput } from '@amzn/amazon-music-interlude-catalog-manager-client';
import { ServerInterlude } from '../data-types';
import { DateTime } from 'luxon';

export function formatInterludes(interlude: InterludeOutput): ServerInterlude {
    /**
     * Transforms an interlude object as returned by the Catalog Manager service into the format
     * expected by the Forge Website
     */
    let crtTime: string;
    if (interlude.creationTime) {
        crtTime = DateTime.fromMillis(interlude.creationTime).toLocaleString(DateTime.DATETIME_SHORT);
    } else {
        //no creation time => empty string
        crtTime = '';
    }
    const image_assets = interlude.assets.images?.map((asset) => ({ id: asset })) ?? [];
    const serverInterlude: ServerInterlude = {
        id: interlude.id,
        crt_time: crtTime,
        data_stage: interlude.stage,
        a_type: interlude.assemblyType,
        c_type: interlude.contentType,
        pos: interlude.position,
        rank: interlude.rank,
        lang: interlude.languages,
        mtr_list: interlude.territories,
        tiers: interlude.tiers,
        assets: {
            text: interlude.assets.text ?? [],
            media: interlude.assets.audio ?? [],
            images: image_assets,
        },
        is_explicit: interlude.isExplicit,
        is_edtrl: interlude.isEditorial,
        pri_txt: interlude.primaryText,
        sec_txt: interlude.secondaryText,
        src: interlude.source,
        descr: interlude.description,
    };
    return serverInterlude;
}
